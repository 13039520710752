import type { Addon } from '../../../fsm/answers';
import { AddonName } from '../../../fsm/answers';

export type OptionsPackName = 'liberty' | 'tranquility' | 'trust';

export function getOptionsFromPackName(
  optionsPackName: OptionsPackName | undefined,
): Partial<Record<AddonName, Addon>> {
  switch (optionsPackName) {
    case 'liberty':
      return {
        [AddonName.OML_VEHICLE_REPLACEMENT]: {
          name: AddonName.OML_VEHICLE_REPLACEMENT,
          term: null,
          isSelected: true,
        },
        [AddonName.OML_ASSISTANCE_0_KM_ADDON]: {
          name: AddonName.OML_ASSISTANCE_0_KM_ADDON,
          term: null,
          isSelected: true,
        },
        [AddonName.OML_DRIVER_PROTECTION_500]: {
          name: AddonName.OML_DRIVER_PROTECTION_500,
          term: null,
          isSelected: true,
        },
      };
    case 'tranquility':
      return {
        [AddonName.OML_VEHICLE_REPLACEMENT]: {
          name: AddonName.OML_VEHICLE_REPLACEMENT,
          term: null,
          isSelected: false,
        },
        [AddonName.OML_ASSISTANCE_0_KM_ADDON]: {
          name: AddonName.OML_ASSISTANCE_0_KM_ADDON,
          term: null,
          isSelected: true,
        },
        [AddonName.OML_DRIVER_PROTECTION_500]: {
          name: AddonName.OML_DRIVER_PROTECTION_500,
          term: null,
          isSelected: true,
        },
      };
    case 'trust':
      return {
        [AddonName.OML_VEHICLE_REPLACEMENT]: {
          name: AddonName.OML_VEHICLE_REPLACEMENT,
          term: null,
          isSelected: false,
        },
        [AddonName.OML_ASSISTANCE_0_KM_ADDON]: {
          name: AddonName.OML_ASSISTANCE_0_KM_ADDON,
          term: null,
          isSelected: false,
        },
        [AddonName.OML_DRIVER_PROTECTION_500]: {
          name: AddonName.OML_DRIVER_PROTECTION_500,
          term: null,
          isSelected: true,
        },
      };
    default:
      return {
        [AddonName.OML_VEHICLE_REPLACEMENT]: {
          name: AddonName.OML_VEHICLE_REPLACEMENT,
          term: null,
          isSelected: false,
        },
        [AddonName.OML_ASSISTANCE_0_KM_ADDON]: {
          name: AddonName.OML_ASSISTANCE_0_KM_ADDON,
          term: null,
          isSelected: false,
        },
        [AddonName.OML_DRIVER_PROTECTION_500]: {
          name: AddonName.OML_DRIVER_PROTECTION_500,
          term: null,
          isSelected: false,
        },
      };
  }
}

export function getDefaultSelectedPackFromAddonsList(
  addons?: Partial<Record<AddonName, Addon>>,
): OptionsPackName | undefined {
  if (addons?.OMLVehicleReplacement && addons.OMLVehicleReplacement.isSelected) {
    return 'liberty';
  }
  if (addons?.OMLAssistance0km && addons.OMLAssistance0km.isSelected) {
    return 'tranquility';
  }
  if (addons?.OMLDriverProtection500 && addons.OMLDriverProtection500.isSelected) {
    return 'trust';
  }

  return undefined;
}

export function getPackTitleFromPackName(packName: OptionsPackName | undefined): string {
  switch (packName) {
    case 'liberty':
      return 'Liberté';
    case 'tranquility':
      return 'Tranquillité';
    case 'trust':
      return 'Confiance';
    default:
      return '';
  }
}
