import type { QuoteOptions } from '../../../apis/types/QuoteOptions';
import { getFilteredSelectableAddons } from '../../../fsm/actions/addons';
import type { Addon, AddonName } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';

function getAddonsComputedPrice(quoteOptions: QuoteOptions, selectedAddons: Addon[]): number {
  // eslint-disable-next-line unicorn/no-array-reduce
  return selectedAddons.reduce((acc: number, { name, term }: Addon) => {
    const foundQuoteOption = quoteOptions.addOns.find((addOn) => addOn.name === name);

    if (foundQuoteOption) {
      const foundOption = foundQuoteOption.options.find((option) => option.term === term);

      if (foundOption) {
        return acc + foundOption.cost.cents;
      }
    }

    return acc;
  }, 0);
}

export const useAddonsPrice = (): number => {
  const {
    context: { answers, quoteOptions },
  } = useSubscriptionFsmState();

  if (answers.addons && quoteOptions) {
    return getAddonsComputedPrice(
      quoteOptions,
      Object.values(getFilteredSelectableAddons(answers.addons)).filter((addon) => addon.isSelected),
    );
  }

  return 0;
};

export function useAddonsPriceFromAddonsList(selectedAddons: Partial<Record<AddonName, Addon>>): number {
  const {
    context: { quoteOptions },
  } = useSubscriptionFsmState();

  if (quoteOptions) {
    return getAddonsComputedPrice(
      quoteOptions,
      Object.values(getFilteredSelectableAddons(selectedAddons)).filter((addon) => addon.isSelected),
    );
  }

  return 0;
}
