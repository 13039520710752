import { Button, HStack, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { MRH_CTA_CLICK } from '../../constants/eventName';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { sendEvent } from '../../utils/mixpanel';
import MrhMobileSVG from './assets/mrh-promote-mobile.svg';
import MrhSVG from './assets/mrh-promote.svg';

export interface MrhRedirectionProps {
  slug: string;
  withBanner?: boolean;
  utmCampaign: string;
}

export function MrhRedirection({ slug, withBanner, utmCampaign }: MrhRedirectionProps): ReactNode | null {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const Component = isDesktop ? HStack : VStack;

  const handleClick = (): void => {
    sendEvent(MRH_CTA_CLICK, {
      cta_action: 'Redirection to Acheel MRH subscription flow',
      cta_name: import.meta.env.VITE_MRH_REDIRECTION_LINK,
      cta_text: slug,
    });
  };

  const redirectButton = (
    <View width={{ base: '100%', large: 'fit-content' }} borderRadius={30} backgroundColor="white">
      <Button
        stretch={!isDesktop}
        type="subtle"
        href={`${import.meta.env.VITE_MRH_REDIRECTION_LINK}?utm_source=auto&utm_campaign=${utmCampaign}`}
        hrefAttrs={{ target: '_blank' }}
        onPress={handleClick}
      >
        Découvrir mon tarif
      </Button>
    </View>
  );

  return (
    <View>
      {withBanner ? (
        <VStack backgroundColor="#7B66FF" borderRadius={20} space={{ base: 'kitt.6', large: 0, medium: 0 }}>
          <img src={isDesktop ? MrhSVG : MrhMobileSVG} alt="" />
          <VStack marginX="kitt.4" marginTop="-kitt.14" marginBottom="kitt.4">
            {redirectButton}
          </VStack>
        </VStack>
      ) : (
        <VStack backgroundColor="#F0EEE9" borderRadius={20}>
          <VStack space="kitt.4" margin="kitt.4">
            <VStack>
              <View
                backgroundColor="#ff5833"
                width="fit-content"
                borderRadius="kitt.1"
                paddingTop={2}
                paddingBottom={1}
                paddingX="kitt.2"
              >
                <Typography.Text base="body-l" style={{ fontFamily: 'transducer' }} color="white">
                  Nouveau chez Ornikar !
                </Typography.Text>
              </View>
            </VStack>
            <Component space={{ base: 'kitt.4' }}>
              <Typography.Text base="body-l" variant="bold" style={{ fontFamily: 'transducer' }}>
                Votre assurance habitation à partir de 2,17€/mois
              </Typography.Text>
              <VStack>{redirectButton}</VStack>
            </Component>
          </VStack>
        </VStack>
      )}
    </View>
  );
}
