import { IllustratedIcon, UrgencyCase } from '@ornikar/illustrated-icons';
import { HStack, Typography, VStack, View, theme } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { OptionsPackTag } from '../../OptionsPack/OptionsPackTag';

export function DriverProtection500(): ReactNode {
  return (
    <VStack space="kitt.6">
      <VStack space="kitt.3">
        <HStack>
          <OptionsPackTag label="Protection du conducteur étendue" />
        </HStack>

        <HStack space="kitt.2" alignItems="center">
          <IllustratedIcon icon={<UrgencyCase />} />

          <Typography.Text base="body-m" variant="bold">
            Couvert jusqu’à 500 000€ en cas d’accident
          </Typography.Text>
        </HStack>
      </VStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-m" variant="bold">
          Quand vous êtes victime avec votre véhicule assuré :
        </Typography.Text>
        <VStack>
          <Typography.Text base="body-m">• d'un accident responsable, </Typography.Text>
          <Typography.Text base="body-m">• d'un incendie.</Typography.Text>
        </VStack>
      </VStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-m" variant="bold">
          Nous assurons :
        </Typography.Text>
        <VStack>
          <Typography.Text base="body-m">
            • les dommages corporels (blessures, souffrances physiques) subis par le conducteur du véhicule assuré,
          </Typography.Text>
          <Typography.Text base="body-m">• les frais médicaux liés à l’accident,</Typography.Text>
          <Typography.Text base="body-m">• les pertes de revenus professionnels,</Typography.Text>
          <Typography.Text base="body-m">
            • les frais liés aux aides-soignants à domicile et aux aides ménagères,
          </Typography.Text>
          <Typography.Text base="body-m">
            • le versement d’un capital aux proches du conducteur en cas de décès.
          </Typography.Text>
        </VStack>
      </VStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-m" variant="bold">
          Quelle indemnisation ?
        </Typography.Text>
        <VStack>
          <Typography.Text base="body-m">Jusqu'à 500 000€ de remboursement.</Typography.Text>
        </VStack>
      </VStack>

      <View borderRadius="kitt.5" backgroundColor={theme.colors.uiBackground} padding="kitt.4">
        <VStack space="kitt.2">
          <Typography.Text base="body-m" variant="bold">
            Exemple
          </Typography.Text>
          <Typography.Text base="body-m">
            Sur l'autoroute des vacances, vous perdez le contrôle de votre voiture et vous vous blessez. A votre retour,
            des frais d’aide ménagère pour vous aider peuvent être pris en charge.
          </Typography.Text>
        </VStack>
      </View>
    </VStack>
  );
}
