import { Typography, View, theme } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface OptionsPackTagProps {
  label: string;
}

export function OptionsPackTag({ label }: OptionsPackTagProps): ReactNode {
  return (
    <View backgroundColor={theme.palettes.lateOcean['violine.2']} borderRadius={10} paddingY={1} paddingX="kitt.2">
      <Typography.Text color={theme.palettes.lateOcean['violine.9']} base="body-s">
        {label}
      </Typography.Text>
    </View>
  );
}
