import { Button, Tag, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { addonNameMapping } from '../../../constants/options';
import { useSubscriptionFsmState } from '../../../fsm/context';
import type { OptionsPackName } from '../../../screens/Options/OptionsPackSelection/OptionsPackSelection.helpers';
import { getPackTitleFromPackName } from '../../../screens/Options/OptionsPackSelection/OptionsPackSelection.helpers';
import { formatPeriodicity } from '../../../utils/formatPeriodicity';
import { formatPrice } from '../../../utils/formatPrice';
import { usePaymentPeriodicityDetails } from '../../../utils/price';
import { useAddonsPrice } from '../hooks/useAddonsPrice';
import { useDeductiblesPrice } from '../hooks/useDeductiblesPrice';
import { useSelectedAddonsNames } from '../hooks/useSelectedAddonsNames';
import styles from './styles.module.css';

interface FormulaPriceBottomBarProps {
  buttonLabel?: string;
  onSubmit: (() => void) | undefined;
  disabled?: boolean;
  selectedPack?: OptionsPackName;
}

export function FormulaPriceBottomBar({
  buttonLabel = 'Continuer',
  onSubmit,
  disabled = false,
  selectedPack,
}: FormulaPriceBottomBarProps): ReactNode {
  const {
    context: { formulePickedObject },
  } = useSubscriptionFsmState();

  const selectedAddonsNames = useSelectedAddonsNames();

  const packageLevel = formulePickedObject?.name;

  const { price: formulaPrice, periodicity: paymentPeriodicity } = usePaymentPeriodicityDetails();

  const addonsPrice = useAddonsPrice();
  const deductiblesPrice = useDeductiblesPrice();
  const price = formatPrice(formulaPrice + addonsPrice + deductiblesPrice);
  const periodicity = formatPeriodicity(paymentPeriodicity);

  return (
    <div className={styles.BottomBar}>
      <Typography.Text variant="bold" base="header5" textAlign="center">
        {packageLevel}
        {selectedPack ? (
          <Typography.Text variant="regular"> [{getPackTitleFromPackName(selectedPack)}]</Typography.Text>
        ) : null}{' '}
        •{' '}
        <Typography.Text color="primary">
          {price} €/{periodicity}
        </Typography.Text>
      </Typography.Text>

      {selectedAddonsNames.length > 0 ? (
        <div className={styles.CardTags}>
          {selectedAddonsNames.map((addonName) => (
            <Tag key={addonName} label={addonNameMapping[addonName]} type="primary" />
          ))}
        </div>
      ) : null}

      {onSubmit ? (
        <div className={styles.ButtonContainer}>
          <Button stretch type="primary" disabled={disabled} onPress={onSubmit}>
            {buttonLabel}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
