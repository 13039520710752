import { OPTIONS_PRESENTATION_MODE, REGISTRATION_DOCUMENT_PARTNER_LINK } from '../../constants/flagshipKeys';
import { getFsFlag } from '../../setup/flagship';

export const isRegistrationDocumentPartnerLinkDisplayed = (): boolean => {
  const registrationDocumentPartnerLinkDisplayFlag = getFsFlag<boolean>(REGISTRATION_DOCUMENT_PARTNER_LINK, false, {
    shouldSendVariationToMixpanel: true,
  });

  return registrationDocumentPartnerLinkDisplayFlag.getValue();
};

export const isTinderStyleOptions = (): boolean => {
  const optionsPresentationMode = getFsFlag<string>(OPTIONS_PRESENTATION_MODE, 'tinder', {
    shouldSendVariationToMixpanel: true,
  });

  return optionsPresentationMode.getValue() === 'tinder';
};
