import { Breakdown, IllustratedIcon } from '@ornikar/illustrated-icons';
import { HStack, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { OptionsPackTag } from '../../OptionsPack/OptionsPackTag';

export function Assistance0Km(): ReactNode {
  return (
    <VStack space="kitt.6">
      <VStack space="kitt.3">
        <HStack>
          <OptionsPackTag label="Assistance 0km" />
        </HStack>

        <HStack space="kitt.2" alignItems="center">
          <IllustratedIcon icon={<Breakdown />} />

          <Typography.Text base="body-m" variant="bold">
            Dépanné n'importe où, même en bas de chez vous
          </Typography.Text>
        </HStack>
      </VStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-m" variant="bold">
          Quand vous :
        </Typography.Text>
        <VStack>
          <Typography.Text base="body-m">• tombez en panne,</Typography.Text>
          <Typography.Text base="body-m">• êtes victime d’un accident,</Typography.Text>
          <Typography.Text base="body-m">• subissez un vol.</Typography.Text>
        </VStack>
      </VStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-m" variant="bold">
          Nous assurons :
        </Typography.Text>
        <VStack>
          <Typography.Text base="body-m">
            • l'assistance et le remorquage de votre véhicule en moins d’une heure en direction du garage le plus
            proche,
          </Typography.Text>
          <Typography.Text base="body-m">• votre rapatriement et celui de vos passagers à domicile,</Typography.Text>
          <Typography.Text base="body-m">
            • votre hébergement et celui pour vos passagers, si nécessaire.
          </Typography.Text>
        </VStack>
      </VStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-m" variant="bold">
          Quelle indemnisation ?
        </Typography.Text>
        <VStack>
          <Typography.Text base="body-m">• Jusqu’à 180 € pour un remorquage en journée comme de nuit</Typography.Text>
          <Typography.Text base="body-m">• Jusqu’à 80€ par nuit d'hébergement</Typography.Text>
        </VStack>
      </VStack>
    </VStack>
  );
}
