import type { IllustratedIconSize } from '@ornikar/illustrated-icons';
import { IllustratedIcon } from '@ornikar/illustrated-icons';
import { ShieldFillIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, Pressable, Toggle, Typography, VStack, View, theme } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { formatPrice } from '../../utils/formatPrice';
import { OptionsPackTag } from './OptionsPackTag';

interface OptionsPackProps {
  name: string;
  stars: number;
  selected: boolean;
  options: string[];
  price: number;
  totalPrice: number;
  periodicity: 'monthly' | 'yearly' | undefined;
  toggle: () => void;
  onPressFooterButton: () => void;
}

export function OptionsPack({
  name,
  selected = false,
  stars,
  options,
  price,
  periodicity,
  totalPrice,
  toggle,
  onPressFooterButton,
}: OptionsPackProps): ReactNode {
  const textColor = selected ? theme.colors.white : theme.colors.black;

  return (
    <Pressable
      backgroundColor={selected ? theme.colors.primary : theme.colors.white}
      paddingBottom={0}
      borderWidth={1}
      borderColor={theme.palettes.lateOcean['eggshell.4']}
      borderStyle={selected ? 'solid' : 'dashed'}
      borderRadius="kitt.2"
      onPress={toggle}
    >
      <VStack space="kitt.6" padding={{ base: 'kitt.4', medium: 'kitt.6' }}>
        <HStack justifyContent="space-between">
          <HStack space="kitt.2" alignItems="center">
            <Typography.Text base="heading-impact-s" color={textColor}>
              {name}
            </Typography.Text>

            <HStack space="kitt.1">
              <IllustratedIcon
                size={24 as IllustratedIconSize}
                icon={<ShieldFillIcon fill={theme.palettes.lateOcean['green.8']} />}
              />
              <IllustratedIcon
                size={24 as IllustratedIconSize}
                icon={
                  <ShieldFillIcon
                    fill={stars >= 2 ? theme.palettes.lateOcean['green.8'] : theme.palettes.lateOcean['eggshell.4']}
                  />
                }
              />
              <IllustratedIcon
                size={24 as IllustratedIconSize}
                icon={
                  <ShieldFillIcon
                    fill={stars >= 3 ? theme.palettes.lateOcean['green.8'] : theme.palettes.lateOcean['eggshell.4']}
                  />
                }
              />
            </HStack>
          </HStack>

          <Toggle value={selected} variant={selected ? 'accent' : 'primary'} onChange={toggle} />
        </HStack>

        <HStack flexWrap="wrap" space="kitt.2" marginTop="-kitt.2">
          {options.map((option) => (
            <View key={option} marginTop="kitt.2">
              <OptionsPackTag label={option} />
            </View>
          ))}
        </HStack>

        <VStack space={0} alignItems="flex-end">
          <HStack alignItems="flex-end">
            <Typography.Text base="header-impact-s" color={textColor}>
              +{formatPrice(price)}€
            </Typography.Text>
            <Typography.Text base="heading-xxs" color={textColor}>
              {' '}
              /{periodicity === 'monthly' ? 'mois' : 'an'}
            </Typography.Text>
          </HStack>

          <Typography.Text base="body-s" color={selected ? theme.colors.white : theme.palettes.lateOcean['eggshell.9']}>
            Soit votre assurance à : {formatPrice(totalPrice)}€ /{periodicity === 'monthly' ? 'mois' : 'an'}
          </Typography.Text>
        </VStack>
      </VStack>

      <HStack
        justifyContent="center"
        width="100%"
        borderTopWidth={1}
        paddingY="kitt.2"
        borderTopColor={theme.palettes.lateOcean['eggshell.4']}
      >
        <Button type={selected ? 'subtle-revert' : 'subtle'} onPress={onPressFooterButton}>
          Voir les services inclus
        </Button>
      </HStack>
    </Pressable>
  );
}
