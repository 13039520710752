import { HStack, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { OptionsPackTag } from '../../OptionsPack/OptionsPackTag';

export function VehicleReplacement(): ReactNode {
  return (
    <VStack space="kitt.6">
      <HStack>
        <OptionsPackTag label="Véhicule de remplacement" />
      </HStack>

      <VStack space="kitt.2">
        <Typography.Text base="body-l" variant="bold">
          Nous vous prêtons un véhicule quand le vôtre :
        </Typography.Text>

        <VStack>
          <Typography.Text base="body-m">• est immobilisé plus de 24h, </Typography.Text>
          <Typography.Text base="body-m">• nécessite au moins 2h de réparation.</Typography.Text>
        </VStack>
      </VStack>
    </VStack>
  );
}
