import { VStack } from '@ornikar/kitt-universal';
import { type ReactNode, useEffect, useState } from 'react';
import { FormulaPriceBottomBar } from '../../../components/FormulaPrice/FormulaPriceBottomBar';
import { FormulaPriceCard } from '../../../components/FormulaPrice/FormulaPriceCard';
import { useAddonsPriceFromAddonsList } from '../../../components/FormulaPrice/hooks/useAddonsPrice';
import { useDeductiblesPrice } from '../../../components/FormulaPrice/hooks/useDeductiblesPrice';
import { OptionsPack } from '../../../components/OptionsPack';
import { OptionsPackInfoModal } from '../../../components/OptionsPackInfoModal';
import { ScreenTemplateWithSideAndBottomBars } from '../../../components/ScreenTemplates/ScreenTemplateWithSideAndBottomBars';
import type { AddonName } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import { sendEvent } from '../../../utils/mixpanel';
import { usePaymentPeriodicityDetails } from '../../../utils/price';
import {
  type OptionsPackName,
  getDefaultSelectedPackFromAddonsList,
  getOptionsFromPackName,
  getPackTitleFromPackName,
} from './OptionsPackSelection.helpers';

export function OptionsPackSelectionScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();
  useSendPageNameToSegment();

  const {
    context: { answers, paymentPeriodicity, tarificationResult },
  } = useSubscriptionFsmState();
  const quoteId = tarificationResult ? tarificationResult.quoteId : undefined;

  const defaultSelectedPack = getDefaultSelectedPackFromAddonsList(answers.addons);

  const [selectedPack, setSelectedPack] = useState<OptionsPackName | undefined>(defaultSelectedPack);
  const [openedInfoModal, setOpenedInfoModal] = useState<OptionsPackName | undefined>(undefined);

  const deductiblesPrice = useDeductiblesPrice();
  const { price: formulaPrice } = usePaymentPeriodicityDetails();

  const basePrice = formulaPrice + deductiblesPrice;

  const libertyPackOptionsPricing = useAddonsPriceFromAddonsList(getOptionsFromPackName('liberty'));
  const libertyPackPrice = basePrice + libertyPackOptionsPricing;

  const tranquilityPackOptionsPricing = useAddonsPriceFromAddonsList(getOptionsFromPackName('tranquility'));
  const tranquilityPackPrice = basePrice + tranquilityPackOptionsPricing;

  const trustPackOptionsPricing = useAddonsPriceFromAddonsList(getOptionsFromPackName('trust'));
  const trustPackPricing = basePrice + trustPackOptionsPricing;

  useEffect(() => {
    const options = getOptionsFromPackName(selectedPack);

    send(Event.ANSWER_WITHOUT_CONTINUING, {
      answers: {
        addons: options,
      },
    });

    const optionsKeys = Object.keys(options);

    optionsKeys.forEach((addonName) => {
      sendEvent('Decide On Option', {
        option_name: addonName,
        include_option: options[addonName as AddonName]?.isSelected,
        decision_method: 'click',
        decision_step: 'option_state',
        package: selectedPack,
        quote_id: quoteId,
      });
    });
  }, [selectedPack, send, quoteId]);

  const handleSubmit = (): void => {
    send(Event.CONTINUE);
  };

  return (
    <ScreenTemplateWithSideAndBottomBars
      hideSaveQuoteButton
      hideSubmitButton={!selectedPack}
      title="Quels services vous intéressent ?"
      submitButton={{
        label: 'Continuer',
      }}
      sideBar={<FormulaPriceCard buttonLabel="Continuer" selectedPack={selectedPack} onSubmit={handleSubmit} />}
      bottomBar={<FormulaPriceBottomBar buttonLabel="Continuer" selectedPack={selectedPack} onSubmit={handleSubmit} />}
      onSubmit={handleSubmit}
    >
      <VStack space="kitt.4">
        <OptionsPack
          name={getPackTitleFromPackName('liberty')}
          options={['Véhicule de remplacement', 'Assistance 0km', 'Protection du conducteur étendue']}
          selected={selectedPack === 'liberty'}
          stars={3}
          price={libertyPackPrice - basePrice}
          totalPrice={libertyPackPrice}
          periodicity={paymentPeriodicity}
          toggle={() => {
            if (selectedPack === 'liberty') {
              setSelectedPack(undefined);
            } else {
              setSelectedPack('liberty');
            }
          }}
          onPressFooterButton={() => {
            setOpenedInfoModal('liberty');
          }}
        />
        <OptionsPackInfoModal
          packName="liberty"
          isVisible={openedInfoModal === 'liberty'}
          onClose={() => {
            setOpenedInfoModal(undefined);
          }}
        />

        <OptionsPack
          name={getPackTitleFromPackName('tranquility')}
          options={['Assistance 0km', 'Protection du conducteur étendue']}
          selected={selectedPack === 'tranquility'}
          stars={2}
          price={tranquilityPackPrice - basePrice}
          totalPrice={tranquilityPackPrice}
          periodicity={paymentPeriodicity}
          toggle={() => {
            if (selectedPack === 'tranquility') {
              setSelectedPack(undefined);
            } else {
              setSelectedPack('tranquility');
            }
          }}
          onPressFooterButton={() => {
            setOpenedInfoModal('tranquility');
          }}
        />
        <OptionsPackInfoModal
          packName="tranquility"
          isVisible={openedInfoModal === 'tranquility'}
          onClose={() => {
            setOpenedInfoModal(undefined);
          }}
        />

        <OptionsPack
          name={getPackTitleFromPackName('trust')}
          options={['Protection du conducteur étendue']}
          selected={selectedPack === 'trust'}
          stars={1}
          price={trustPackPricing - basePrice}
          totalPrice={trustPackPricing}
          periodicity={paymentPeriodicity}
          toggle={() => {
            if (selectedPack === 'trust') {
              setSelectedPack(undefined);
            } else {
              setSelectedPack('trust');
            }
          }}
          onPressFooterButton={() => {
            setOpenedInfoModal('trust');
          }}
        />
        <OptionsPackInfoModal
          packName="trust"
          isVisible={openedInfoModal === 'trust'}
          onClose={() => {
            setOpenedInfoModal(undefined);
          }}
        />
      </VStack>
    </ScreenTemplateWithSideAndBottomBars>
  );
}
