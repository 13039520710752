import { XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { CardModal, IconButton, NavigationModal, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import {
  type OptionsPackName,
  getOptionsFromPackName,
  getPackTitleFromPackName,
} from '../../screens/Options/OptionsPackSelection/OptionsPackSelection.helpers';
import { Separator } from '../PromoCode/Separator';
import { Assistance0Km } from './options/Assistance0Km';
import { DriverProtection500 } from './options/DriverProtection500';
import { VehicleReplacement } from './options/VehicleReplacement';

interface InfoModalProps {
  packName?: OptionsPackName;
  isVisible: boolean;
  onClose: () => void;
}

export function OptionsPackInfoModal({ packName, isVisible, onClose }: InfoModalProps): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);
  const title = getPackTitleFromPackName(packName);
  const options = getOptionsFromPackName(packName);

  const modalHeader = (
    <View minWidth="100%">
      <Typography.Paragraph variant="bold">Pack {title}</Typography.Paragraph>
    </View>
  );

  const modalBody = (
    <View minWidth="100%">
      <VStack space="kitt.6">
        {options?.OMLDriverProtection500?.isSelected ? <DriverProtection500 /> : null}
        {options?.OMLAssistance0km?.isSelected ? (
          <>
            <Separator />
            <Assistance0Km />
          </>
        ) : null}
        {options?.OMLVehicleReplacement?.isSelected ? (
          <>
            <Separator />
            <VehicleReplacement />
          </>
        ) : null}
      </VStack>
    </View>
  );

  return isMedium ? (
    <CardModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <CardModal>
        <CardModal.Header right={<IconButton icon={<XRegularIcon />} onPress={() => onClose()} />} title={title} />
        <CardModal.Body overflowY="auto" maxHeight="70vh">
          {modalBody}
        </CardModal.Body>
      </CardModal>
    </CardModal.ModalBehaviour>
  ) : (
    <NavigationModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <NavigationModal
        header={
          <NavigationModal.Header right={<IconButton icon={<XRegularIcon />} onPress={onClose} />}>
            {modalHeader}
          </NavigationModal.Header>
        }
        body={
          <NavigationModal.Body overflowY="auto" minHeight="100vh">
            {modalBody}
          </NavigationModal.Body>
        }
      />
    </NavigationModal.ModalBehaviour>
  );
}
