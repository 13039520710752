import type { MachineConfig, StateMachine } from 'xstate';
import { createMachine } from 'xstate';
import { promoCodeCloseSession } from '../apis/promoCode';
import { getQuote } from '../apis/requestTarification';
import { sendQuoteOptions } from '../apis/sendQuoteOptions';
import type { TarificationResultOK } from '../apis/types/Quotation';
import { listVersions } from '../apis/vehicules';
import { checkAndTransformAddons } from './actions/addons';
import {
  declareSecondaryDriver,
  deleteSecondaryDriver,
  resetDriverBonusYearsAndInsurancePeriods,
  resetDriverHasBeenCovered,
  resetInsurancePeriods,
  setInsurancePeriods,
  setPrimaryDriverUniqueDefaultLicenseDate,
} from './actions/driver';
import { changeRegistrationDocumentOwnerToSubscriber } from './actions/driving';
import {
  copyAnswers,
  setDefaultAnswers,
  setReachedSummaryScreenSectionBuilder,
  unsetDefaultAnswers,
} from './actions/general';
import { hamonCheck } from './actions/hamon';
import {
  decrementEditingInsuranceRecord,
  deleteInsuranceRecordFromEvent,
  incrementEditingInsuranceRecord,
  incrementEditingInsuranceRecordIfOneAlreadyDeclared,
  initializeEditingInsuranceRecord,
  resetCancellationOrSuspensionAlcoholLevel,
  resetCancellationOrSuspensionClaim,
  resetCancellationOrSuspensionClaimAlcoholLevel,
  resetCancellationOrSuspensionTrafficControl,
  resetCancellationsAndSuspensions,
  resetClaimAccident,
  resetClaimAlcoholLevel,
  resetClaimWithAlcoholAnswers,
  resetClaims,
  resetTermination,
  resetTerminationCancellationOrSuspensionAlcoholLevel,
  resetTerminationCancellationOrSuspensionClaim,
  resetTerminationCancellationOrSuspensionClaimAlcoholLevel,
  resetTerminationCancellationOrSuspensionRecord,
  resetTerminationCancellationOrSuspensionTrafficControl,
  resetTerminationCountForNonPayment,
  resetTerminationSuspensionDuration,
  setWithdrawalType,
  skipAllNextCancellationOrSuspensionRecords,
  updateEditingInsuranceRecordFromEvent,
  updateToLastIndexEditingInsuranceRecord,
} from './actions/insuranceRecord';
import {
  clearDeductibles,
  markQuoteMailNotificationAsReceived,
  onTarificationError,
  onTarificationResult,
  resetError,
  resetFicMailDisplay,
  resetQuoteMailNotification,
  resetTarificationResult,
  saveQuoteResult,
  showFicNotification,
} from './actions/quote';
import { resetSubscription, savePickedFormule, setDefaultDeductibles } from './actions/subscription';
import { sendErrorToSentry } from './actions/utils';
import {
  changeVehiclePreSelectionType,
  resetVehicleAvailableVersion,
  resetVehicleCurrentContractAnniversaryMonth,
  resetVehicleInfoFromBrand,
  resetVehicleInfoFromEnergy,
  resetVehicleInfoFromHorsePower,
  resetVehicleInfoFromModel,
  resetVehicleInfoFromVehicleAcquisition,
  resetVehicleInfoOnSkip,
  resetVehicleInfoOrNot,
  resetVehiculeDejaAssureAvecNom,
  setIsLoading,
  setVehicleAvailableVersions,
  setVehicleInfo,
  setVehicleUniqueAvailableVersion,
  setVehicleVersion,
} from './actions/vehicle';
import { InsuranceRecordType, VehiculePreSelectionType, WithdrawalType } from './answers';
import { initializeContext } from './context';
import { buildNavigationEvents } from './eventBuilders';
import {
  driverHas50PercentBonus,
  driverHasBeenCoveredContinuously,
  driverHasBeenCoveredOnPeriod,
  driverHasBeenCoveredPartially,
  driverHasNeverBeenCovered,
  driverHasNotBeenCoveredOnPeriod,
  hasDeclaredSecondaryDriver,
  hasUniqueDefaultLicenseDateOption,
  primaryDriverHasDefaultLicenseDate,
} from './guards/driver';
import { registrationDocumentOwnerIsNotSubscriber } from './guards/driving';
import { isTinderStyleOptions } from './guards/flagship';
import { hasReachedSummaryScreen } from './guards/general';
import { isEligibleToHamonLaw } from './guards/hamon';
import {
  doesCancellationRecordInvolveAlcohol,
  doesDriverDeclaredAtLeastOneClaim,
  doesDriverDeclaredMultipleClaims,
  doesTerminationCancellationRecordInvolveAlcohol,
  hasBeenCancelledOrSuspended,
  hasDeclaredCancellationRecords,
  hasDeclaredTermination,
  hasPreviousInsuranceRecord,
  hasTerminationRecord,
  isAddingAnotherCancellationOrSuspension,
  isCancellationClaimUnderTheInfluenceOfAlcohol,
  isCancellationRecordRelatedToAClaim,
  isClaimAnotherDriverBehindTheWheel,
  isClaimCausedByAnAccident,
  isClaimDriverBehindTheWheel,
  isClaimNotCausedByAnAccident,
  isClaimUnderTheInfluenceOfAlcohol,
  isEditingRecordAClaim,
  isEditingRecordASuspension,
  isNextRecordASuspension,
  isRecordASuspension,
  isTerminatedForCancellation,
  isTerminatedForNonPayment,
  isTerminatedForSuspension,
  isTerminationCancellationClaimUnderTheInfluenceOfAlcohol,
  isTerminationCancellationRelatedToAClaim,
} from './guards/insuranceRecord';
import { haveDeductibles } from './guards/option';
import {
  hasUnderwritingIssues,
  isInsuredSinceLessThanAYear,
  needSalesValidation,
  noProviderAvailable,
  tarificationFailed,
} from './guards/quote';
import { and, not, or } from './guards/utils';
import {
  hasDefaultMiseEnCirculation,
  hasUniqueAvailableVersion,
  isBrandSelection,
  isLicensePlateSelection,
  isVehicleAlreadyInPossession,
  isVehicleCovered,
  isVehicleCoveredWithSubscriberName,
  isVehicleCoveredWithoutSubscriberName,
  isVehicleNotInPossession,
} from './guards/vehicle';
import { DefaultAnswersEnum, Driver, Event, Section, State } from './types';
import type { Context, Events, InsuranceFsmConfig, Schema, StateMachineConfig } from './types';
import { generateTargets } from './utils/targetsGenerator';

export const initialStateMachineConfig: StateMachineConfig = {
  id: '',
  context: initializeContext(),
  stateValue: State.VEHICULE_SEARCH_CHOICE,
};

const machineDefinition: MachineConfig<Context, Schema, Events> = {
  on: {
    ...buildNavigationEvents(),
  },
  states: {
    // MY VEHICLE SECTION
    [State.VEHICULE_SEARCH_CHOICE]: {
      type: 'atomic',
      on: {
        [Event.ANSWER]: [
          {
            target: State.VEHICULE_LICENSE_PLATE,
            actions: [copyAnswers],
            cond: isLicensePlateSelection,
          },
          {
            target: State.VEHICULE_BRAND,
            actions: [resetVehicleInfoOrNot, copyAnswers],
          },
        ],
      },
    },
    [State.VEHICULE_LICENSE_PLATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.VEHICULE_ACQUISITON_DATE,
            cond: hasUniqueAvailableVersion,
          },
          {
            target: State.VEHICULE_VERSION,
            actions: [setVehicleInfo],
          },
        ],
        [Event.SKIP]: {
          target: State.VEHICULE_BRAND,
          actions: [resetVehicleInfoOnSkip, changeVehiclePreSelectionType(VehiculePreSelectionType.BRAND)],
        },
        [Event.BACK]: { target: State.VEHICULE_SEARCH_CHOICE },
      },
    },
    [State.VEHICULE_BRAND]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICULE_MODEL,
          actions: resetVehicleInfoFromBrand,
        },
        [Event.SKIP]: {
          target: State.VEHICULE_LICENSE_PLATE,
          actions: [resetVehicleInfoOnSkip, changeVehiclePreSelectionType(VehiculePreSelectionType.LICENSE_PLATE)],
        },
        [Event.BACK]: { target: State.VEHICULE_SEARCH_CHOICE },
      },
    },
    [State.VEHICULE_MODEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICULE_PUISSANCE_FISCALE,
          actions: resetVehicleInfoFromModel,
        },
        [Event.BACK]: { target: State.VEHICULE_BRAND },
      },
    },
    [State.VEHICULE_PUISSANCE_FISCALE]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICULE_CARBURANT,
          actions: [resetVehicleInfoFromHorsePower, unsetDefaultAnswers(DefaultAnswersEnum.VEHICULE_PUISSANCE_FISCALE)],
        },
        [Event.DEFAULT_ANSWER]: {
          target: State.VEHICULE_CARBURANT,
          actions: [resetVehicleInfoFromHorsePower, setDefaultAnswers(DefaultAnswersEnum.VEHICULE_PUISSANCE_FISCALE)],
        },
        [Event.BACK]: { target: State.VEHICULE_MODEL },
      },
    },
    [State.VEHICULE_CARBURANT]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICULE_MISE_EN_CIRCULATION,
          actions: resetVehicleInfoFromEnergy,
        },
        [Event.BACK]: { target: State.VEHICULE_PUISSANCE_FISCALE },
      },
    },
    [State.VEHICULE_MISE_EN_CIRCULATION]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICULE_FETCH_VERSIONS,
          actions: [
            copyAnswers,
            unsetDefaultAnswers(DefaultAnswersEnum.VEHICULE_MISE_EN_CIRCULATION),
            setIsLoading(true),
          ],
        },
        [Event.DEFAULT_ANSWER]: {
          target: State.VEHICULE_DEFAULT_MISE_EN_CIRCULATION,
        },
        [Event.BACK]: { target: State.VEHICULE_CARBURANT },
      },
    },
    [State.VEHICULE_DEFAULT_MISE_EN_CIRCULATION]: {
      on: {
        [Event.DEFAULT_ANSWER]: {
          target: State.VEHICULE_FETCH_VERSIONS,
          actions: [
            copyAnswers,
            setDefaultAnswers(DefaultAnswersEnum.VEHICULE_MISE_EN_CIRCULATION),
            setIsLoading(true),
          ],
        },
        [Event.BACK]: { target: State.VEHICULE_MISE_EN_CIRCULATION },
      },
    },
    [State.VEHICULE_FETCH_VERSIONS]: {
      on: { BACK: State.VEHICULE_CARBURANT },
      invoke: {
        id: 'getVersions',
        src: async (context) =>
          listVersions(
            context.answers.vehiculeMarque,
            context.answers.vehiculeModele,
            context.answers.vehiculePuissanceFiscale,
            context.answers.vehiculeCarburant,
          ),
        onDone: [
          {
            target: State.VEHICULE_ACQUISITON_DATE,
            cond: (_, event) => Array.isArray(event.data) && event.data.length === 1,
            actions: [setVehicleUniqueAvailableVersion, setIsLoading(false)],
          },
          {
            target: State.VEHICULE_VERSION,
            actions: [setVehicleAvailableVersions, setIsLoading(false)],
          },
        ],
        onError: {
          target: State.VEHICULE_VERSION,
          actions: [resetVehicleAvailableVersion, sendErrorToSentry],
        },
      },
    },
    [State.VEHICULE_VERSION]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.VEHICULE_ACQUISITON_DATE,
            actions: [
              setVehicleVersion,
              unsetDefaultAnswers(DefaultAnswersEnum.VEHICULE_VERSION, DefaultAnswersEnum.VEHICULE_IS_UTILITY),
            ],
          },
        ],
        [Event.DEFAULT_ANSWER]: [
          {
            target: State.VEHICULE_ACQUISITON_DATE,
            actions: [
              setVehicleVersion,
              setDefaultAnswers(DefaultAnswersEnum.VEHICULE_VERSION, DefaultAnswersEnum.VEHICULE_IS_UTILITY),
            ],
          },
        ],
        [Event.BACK]: [
          { target: State.VEHICULE_LICENSE_PLATE, cond: isLicensePlateSelection },
          { target: State.VEHICULE_DEFAULT_MISE_EN_CIRCULATION, cond: hasDefaultMiseEnCirculation },
          { target: State.VEHICULE_MISE_EN_CIRCULATION },
        ],
      },
    },
    [State.VEHICULE_ACQUISITON_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICULE_COVERING_DATE,
          actions: [resetVehicleInfoFromVehicleAcquisition],
        },
        [Event.BACK]: [
          {
            target: State.VEHICULE_MISE_EN_CIRCULATION,
            cond: or(isBrandSelection, hasUniqueAvailableVersion),
          },
          { target: State.VEHICULE_VERSION },
        ],
      },
    },
    [State.VEHICULE_COVERING_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.VEHICULE_IS_COVERED,
            actions: [copyAnswers],
            cond: isVehicleAlreadyInPossession,
          },
          { target: State.VEHICULE_FUNDING, actions: [copyAnswers, hamonCheck] },
        ],
        [Event.BACK]: { target: State.VEHICULE_ACQUISITON_DATE },
      },
    },
    [State.VEHICULE_IS_COVERED]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.VEHICULE_COVERED_SINCE,
            cond: isVehicleCoveredWithSubscriberName,
            actions: [copyAnswers],
          },
          {
            target: State.VEHICULE_FUNDING,
            cond: isVehicleCoveredWithoutSubscriberName,
            actions: [copyAnswers, hamonCheck, resetVehicleCurrentContractAnniversaryMonth],
          },
          {
            target: State.VEHICLE_LAST_INSURANCE_END_DATE,
            actions: [copyAnswers, hamonCheck, resetVehicleCurrentContractAnniversaryMonth],
          },
        ],
        [Event.BACK]: { target: State.VEHICULE_COVERING_DATE },
      },
    },
    [State.VEHICULE_IS_COVERED_INCONSISTENCY]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
            cond: isVehicleCovered,
            actions: [copyAnswers, resetDriverHasBeenCovered(Driver.Primary)],
          },
          {
            target: State.VEHICLE_LAST_INSURANCE_END_DATE_INCONSISTENCY,
            actions: [copyAnswers, hamonCheck, resetVehicleCurrentContractAnniversaryMonth],
          },
        ],
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED },
      },
    },
    [State.VEHICLE_LAST_INSURANCE_END_DATE_INCONSISTENCY]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
          actions: [copyAnswers, hamonCheck, resetDriverHasBeenCovered(Driver.Primary)],
        },
        [Event.BACK]: { target: State.VEHICULE_IS_COVERED_INCONSISTENCY },
      },
    },
    [State.VEHICULE_COVERED_SINCE]: {
      on: {
        [Event.ANSWER]: {
          target: State.VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH,
          actions: [copyAnswers, hamonCheck],
        },
        [Event.BACK]: { target: State.VEHICULE_IS_COVERED },
      },
    },
    [State.VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH]: {
      on: {
        [Event.ANSWER]: { target: State.VEHICULE_FUNDING, actions: [copyAnswers] },
        [Event.BACK]: { target: State.VEHICULE_COVERED_SINCE },
      },
    },
    [State.VEHICLE_LAST_INSURANCE_END_DATE]: {
      on: {
        [Event.ANSWER]: { target: State.VEHICULE_FUNDING, actions: [copyAnswers, hamonCheck] },
        [Event.BACK]: { target: State.VEHICULE_IS_COVERED },
      },
    },
    [State.VEHICULE_FUNDING]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.VEHICLE_SUMMARY,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.VEHICULE_COVERING_DATE,
            cond: isVehicleNotInPossession,
          },
          {
            target: State.VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH,
            cond: isVehicleCoveredWithSubscriberName,
          },
          {
            target: State.VEHICULE_IS_COVERED,
            cond: isVehicleCoveredWithoutSubscriberName,
          },
          {
            target: State.VEHICLE_LAST_INSURANCE_END_DATE,
          },
        ],
      },
    },
    [State.VEHICLE_SUMMARY]: {
      entry: setReachedSummaryScreenSectionBuilder([Section.VEHICULE], true),
      on: {
        [Event.RETURN_TO_SCREEN]: generateTargets(Section.VEHICULE),
        [Event.CONTINUE]: [
          {
            target: State.DRIVING_SUMMARY,
            cond: hasReachedSummaryScreen(Section.DRIVING),
          },
          {
            target: State.DRIVING_PRIMARY_LICENSE_DATE,
          },
        ],
        [Event.BACK]: {
          target: State.VEHICULE_FUNDING,
          actions: setReachedSummaryScreenSectionBuilder([Section.VEHICULE], false),
        },
      },
    },
    // MY DRIVING SECTION
    [State.DRIVING_PRIMARY_LICENSE_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.DRIVING_PRIMARY_LICENSE_TYPE,
          actions: [copyAnswers, unsetDefaultAnswers(DefaultAnswersEnum.PRIMARY_DRIVER_LICENSE_DATE)],
        },
        [Event.DEFAULT_ANSWER]: [
          {
            target: State.DRIVING_PRIMARY_LICENSE_TYPE,
            cond: hasUniqueDefaultLicenseDateOption,
            actions: [
              setPrimaryDriverUniqueDefaultLicenseDate,
              setDefaultAnswers(DefaultAnswersEnum.PRIMARY_DRIVER_LICENSE_DATE),
            ],
          },
          {
            target: State.DRIVING_PRIMARY_DEFAULT_LICENSE_DATE,
          },
        ],
        [Event.BACK]: [
          {
            target: State.VEHICLE_SUMMARY,
          },
        ],
      },
    },
    [State.DRIVING_PRIMARY_DEFAULT_LICENSE_DATE]: {
      on: {
        [Event.DEFAULT_ANSWER]: {
          target: State.DRIVING_PRIMARY_LICENSE_TYPE,
          actions: [copyAnswers, setDefaultAnswers(DefaultAnswersEnum.PRIMARY_DRIVER_LICENSE_DATE)],
        },
        [Event.BACK]: {
          target: State.DRIVING_PRIMARY_LICENSE_DATE,
        },
      },
    },
    [State.DRIVING_PRIMARY_LICENSE_TYPE]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_PRIMARY_WAS_ACCOMPANIED, actions: [copyAnswers] },
        [Event.BACK]: [
          {
            target: State.DRIVING_PRIMARY_DEFAULT_LICENSE_DATE,
            cond: and(primaryDriverHasDefaultLicenseDate, not(hasUniqueDefaultLicenseDateOption)),
          },
          { target: State.DRIVING_PRIMARY_LICENSE_DATE },
        ],
      },
    },
    [State.DRIVING_PRIMARY_WAS_ACCOMPANIED]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_VEHICULE_USAGE, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_PRIMARY_LICENSE_TYPE },
      },
    },
    [State.DRIVING_VEHICULE_USAGE]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_KILOMETERS_PER_YEAR, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_PRIMARY_WAS_ACCOMPANIED },
      },
    },
    [State.DRIVING_KILOMETERS_PER_YEAR]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_VEHICULE_PARKING, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_VEHICULE_USAGE },
      },
    },
    [State.DRIVING_VEHICULE_PARKING]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_CITY, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_KILOMETERS_PER_YEAR },
      },
    },
    [State.DRIVING_CITY]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_HOUSING_TYPE, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_VEHICULE_PARKING },
      },
    },
    [State.DRIVING_HOUSING_TYPE]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_IS_PROPERTY_OWNER, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_CITY },
      },
    },
    [State.DRIVING_IS_PROPERTY_OWNER]: {
      on: {
        [Event.ANSWER]: { target: State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER, actions: [copyAnswers] },
        [Event.BACK]: { target: State.DRIVING_HOUSING_TYPE },
      },
    },
    [State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.DRIVING_REGISTRATION_DOCUMENT_OWNER,
            cond: registrationDocumentOwnerIsNotSubscriber,
            actions: [copyAnswers],
          },
          {
            target: State.DRIVING_SUMMARY,
            actions: [changeRegistrationDocumentOwnerToSubscriber],
          },
        ],
        [Event.BACK]: { target: State.DRIVING_IS_PROPERTY_OWNER },
      },
    },
    [State.DRIVING_REGISTRATION_DOCUMENT_OWNER]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.DRIVING_SUMMARY,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: { target: State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER },
      },
    },
    [State.DRIVING_SUMMARY]: {
      entry: setReachedSummaryScreenSectionBuilder([Section.DRIVING], true),
      on: {
        [Event.RETURN_TO_SCREEN]: generateTargets(Section.DRIVING),
        [Event.CONTINUE]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
          },
          {
            target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
          },
        ],
        [Event.BACK]: [
          {
            target: State.DRIVING_REGISTRATION_DOCUMENT_OWNER,
            cond: registrationDocumentOwnerIsNotSubscriber,
            actions: setReachedSummaryScreenSectionBuilder([Section.VEHICULE], false),
          },
          {
            target: State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER,
            actions: setReachedSummaryScreenSectionBuilder([Section.VEHICULE], false),
          },
        ],
      },
    },
    // MY INFORMATION SECTION
    // PRIMARY DRIVER
    [State.INFORMATION_PRIMARY_HAS_BEEN_COVERED]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_PERIODS,
            cond: driverHasBeenCoveredPartially(Driver.Primary),
            actions: [copyAnswers, setInsurancePeriods(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_BONUS_MALUS,
            cond: driverHasBeenCoveredContinuously(Driver.Primary),
            actions: [copyAnswers, resetInsurancePeriods(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_BONUS_MALUS,
            cond: driverHasNotBeenCoveredOnPeriod(Driver.Primary),
            actions: [copyAnswers, resetInsurancePeriods(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [copyAnswers, resetDriverBonusYearsAndInsurancePeriods(Driver.Primary)],
          },
        ],
        [Event.INSURANCE_DATE_INCONSISTENCY]: {
          target: State.VEHICULE_IS_COVERED_INCONSISTENCY,
          actions: [resetVehiculeDejaAssureAvecNom],
        },
        [Event.BACK]: [{ target: State.DRIVING_SUMMARY }],
      },
    },
    [State.INFORMATION_PRIMARY_INSURANCE_PERIODS]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_PRIMARY_BONUS_MALUS, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED },
        [Event.INSURANCE_DATE_INCONSISTENCY]: {
          target: State.VEHICULE_IS_COVERED_INCONSISTENCY,
          actions: [resetVehiculeDejaAssureAvecNom],
        },
      },
    },
    [State.INFORMATION_PRIMARY_BONUS_MALUS]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_BONUS50,
            actions: [copyAnswers],
            cond: driverHas50PercentBonus(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD,
            cond: driverHasBeenCoveredOnPeriod(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
            cond: driverHasBeenCoveredContinuously(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
            cond: driverHasNotBeenCoveredOnPeriod(Driver.Primary),
          },
          { target: State.INFORMATION_PRIMARY_INSURANCE_PERIODS },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_BONUS50]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD,
            cond: driverHasBeenCoveredOnPeriod(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_BONUS_MALUS },
      },
    },
    /** RAG primary driver - start */
    // TERMINATION (INSURANCE CONTRACT)
    [State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_DATE,
            cond: hasTerminationRecord(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [copyAnswers, resetTermination(Driver.Primary)],
          },
        ],
        [Event.BACK]: [
          { target: State.INFORMATION_PRIMARY_BONUS50, cond: driverHas50PercentBonus(Driver.Primary) },
          { target: State.INFORMATION_PRIMARY_BONUS_MALUS },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_REASON,
          actions: [copyAnswers],
        },
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_REASON]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_DATE,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT,
            cond: isTerminatedForNonPayment(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
            cond: or(isTerminatedForCancellation(Driver.Primary), isTerminatedForSuspension(Driver.Primary)),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [
              copyAnswers,
              resetTerminationCountForNonPayment(Driver.Primary),
              resetTerminationCancellationOrSuspensionRecord(Driver.Primary),
            ],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_REASON,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
            cond: or(isTerminatedForCancellation(Driver.Primary), isTerminatedForSuspension(Driver.Primary)),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionRecord(Driver.Primary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION,
            cond: isTerminatedForSuspension(Driver.Primary),
            actions: [copyAnswers, resetTerminationCountForNonPayment(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
            actions: [
              copyAnswers,
              resetTerminationCountForNonPayment(Driver.Primary),
              resetTerminationSuspensionDuration(Driver.Primary),
            ],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT,
            cond: isTerminatedForNonPayment(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE,
            cond: isTerminationCancellationRelatedToAClaim(Driver.Primary),
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionTrafficControl(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionClaim(Driver.Primary)],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION,
            cond: isTerminatedForSuspension(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL,
            cond: doesTerminationCancellationRecordInvolveAlcohol(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionAlcoholLevel(Driver.Primary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL,
            cond: isTerminationCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionClaimAlcoholLevel(Driver.Primary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
        },
      },
    },
    [State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL,
            cond: isTerminationCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
            cond: isTerminationCancellationRelatedToAClaim(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL,
            cond: doesTerminationCancellationRecordInvolveAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
          },
        ],
      },
    },
    // CANCELLATIONS OR SUSPENSIONS (LICENSE)
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE,
            cond: hasBeenCancelledOrSuspended(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY,
            actions: [copyAnswers, resetCancellationsAndSuspensions(Driver.Primary)],
          },
        ],
        [Event.SKIP]: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY,
          actions: [resetCancellationsAndSuspensions(Driver.Primary)],
        },
        [Event.BACK]: [
          { target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED, cond: driverHasNeverBeenCovered(Driver.Primary) },
          {
            target: State.INFORMATION_PRIMARY_BONUS50,
            cond: and(driverHasNotBeenCoveredOnPeriod(Driver.Primary), driverHas50PercentBonus(Driver.Primary)),
          },
          {
            target: State.INFORMATION_PRIMARY_BONUS_MALUS,
            cond: driverHasNotBeenCoveredOnPeriod(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD,
            cond: not(hasDeclaredTermination(Driver.Primary)),
          },
          {
            target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT,
            cond: isTerminatedForNonPayment(Driver.Primary),
          },
          { target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
            cond: isRecordASuspension(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
            actions: [copyAnswers],
          },
        ],
        // TODO [>1]: Check every flow related to INFORMATION_<DRIVER>_ADD_CANCELLATION_RECORD or INFORMATION_<DRIVER>_CANCELLATION_RECORD[
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
            cond: hasPreviousInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension),
            actions: [decrementEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension)],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
          actions: [copyAnswers],
        },
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD,
            cond: isAddingAnotherCancellationOrSuspension(Driver.Primary),
            actions: [decrementEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension)],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE,
            cond: isCancellationRecordRelatedToAClaim(Driver.Primary),
            actions: [copyAnswers, resetCancellationOrSuspensionTrafficControl(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON,
            actions: [copyAnswers, resetCancellationOrSuspensionClaim(Driver.Primary)],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD,
            cond: isAddingAnotherCancellationOrSuspension(Driver.Primary),
            actions: [decrementEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension)],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
            cond: isRecordASuspension(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL,
            actions: [copyAnswers],
            cond: doesCancellationRecordInvolveAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN,
            actions: [copyAnswers, resetCancellationOrSuspensionAlcoholLevel(Driver.Primary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL,
            actions: [copyAnswers],
            cond: isCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN,
            actions: [copyAnswers, resetCancellationOrSuspensionClaimAlcoholLevel(Driver.Primary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL,
            cond: isCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
            cond: isCancellationRecordRelatedToAClaim(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL,
            cond: doesCancellationRecordInvolveAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
            cond: isNextRecordASuspension(Driver.Primary),
            actions: [
              copyAnswers,
              incrementEditingInsuranceRecordIfOneAlreadyDeclared(
                Driver.Primary,
                InsuranceRecordType.CancellationOrSuspension,
              ),
            ],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
            actions: [
              copyAnswers,
              incrementEditingInsuranceRecordIfOneAlreadyDeclared(
                Driver.Primary,
                InsuranceRecordType.CancellationOrSuspension,
              ),
            ],
          },
        ],
        [Event.SKIP]: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY,
          actions: [skipAllNextCancellationOrSuspensionRecords(Driver.Primary)],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN,
        },
      },
    },
    // CLAIMS
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY]: {
      on: {
        [Event.REDIRECT]: [{ target: State.INFORMATION_PRIMARY_TERMINATION_RECORD_REASON }],
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE,
            actions: [copyAnswers, initializeEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.Claim)],
            cond: doesDriverDeclaredAtLeastOneClaim(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers, resetClaims(Driver.Primary)],
          },
        ],
        // TODO [>1]: Check every flow related to INFORMATION_<DRIVER>_ADD_CANCELLATION_RECORD or INFORMATION_<DRIVER>_CANCELLATION_RECORD
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD,
            cond: hasDeclaredCancellationRecords(Driver.Primary),
            actions: [
              updateToLastIndexEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension),
            ],
          },
          { target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_PRIMARY_CLAIM_RECORD_DATE, actions: [copyAnswers] },
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
          },
          {
            target: State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD,
            cond: hasPreviousInsuranceRecord(Driver.Primary, InsuranceRecordType.Claim),
            actions: [decrementEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.Claim)],
          },
          { target: State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER,
            cond: isClaimCausedByAnAccident(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Primary),
            actions: [copyAnswers, resetClaimAccident(Driver.Primary)],
          },
          { target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY, actions: [copyAnswers] },
        ],
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE },
      },
    },
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_DATE,
        },
      },
    },
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL,
            cond: isClaimDriverBehindTheWheel(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers, resetClaimWithAlcoholAnswers(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Primary),
            actions: [copyAnswers, resetClaimWithAlcoholAnswers(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers, resetClaimWithAlcoholAnswers(Driver.Primary)],
          },
        ],
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER },
      },
    },
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL,
            cond: isClaimUnderTheInfluenceOfAlcohol(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers, resetClaimAlcoholLevel(Driver.Primary)],
          },
          {
            target: State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Primary),
            actions: [copyAnswers, resetClaimAlcoholLevel(Driver.Primary)],
          },
          { target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY, actions: [copyAnswers] },
        ],
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL },
      },
    },
    [State.INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Primary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL,
        },
      },
    },
    [State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD]: {
      on: {
        [Event.ADD_CLAIM]: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE,
          actions: [incrementEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.Claim)],
        },
        [Event.CONTINUE]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_DATE,
            cond: isClaimNotCausedByAnAccident(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL,
            cond: isClaimAnotherDriverBehindTheWheel(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL,
            cond: isClaimUnderTheInfluenceOfAlcohol(Driver.Primary),
          },
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL,
          },
        ],
      },
    },
    // SUMMARY
    [State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY]: {
      entry: setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], true),
      on: {
        [Event.RETURN_TO_SCREEN]: generateTargets(Section.PRIMARY_DRIVER),
        [Event.CONTINUE]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
          },
          {
            target: State.INFORMATION_DECLARE_SECONDARY_DRIVER,
          },
        ],
        [Event.EDIT_INSURANCE_RECORD]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE,
            cond: isEditingRecordAClaim,
            actions: [
              updateEditingInsuranceRecordFromEvent(Driver.Primary),
              setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
            ],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE,
            cond: isEditingRecordASuspension(Driver.Primary),
            actions: [
              updateEditingInsuranceRecordFromEvent(Driver.Primary),
              setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
            ],
          },
          {
            target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
            actions: [
              updateEditingInsuranceRecordFromEvent(Driver.Primary),
              setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
            ],
          },
        ],
        [Event.DELETE_INSURANCE_RECORD]: {
          target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
          actions: deleteInsuranceRecordFromEvent(Driver.Primary),
        },
        [Event.START_ADDING_INSURANCE_RECORD]: {
          target: State.INFORMATION_PRIMARY_ADD_INSURANCE_RECORD,
          actions: setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
        },
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY,
            cond: driverHasNeverBeenCovered(Driver.Primary),
            actions: [setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false)],
          },
          {
            target: State.INFORMATION_PRIMARY_BONUS50,
            cond: and(not(driverHasBeenCoveredOnPeriod(Driver.Primary)), driverHas50PercentBonus(Driver.Primary)),
            actions: setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
          },
          {
            target: State.INFORMATION_PRIMARY_BONUS_MALUS,
            cond: not(driverHasBeenCoveredOnPeriod(Driver.Primary)),
            actions: setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
          },
          {
            target: State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD,
            actions: setReachedSummaryScreenSectionBuilder([Section.PRIMARY_DRIVER], false),
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_ADD_INSURANCE_RECORD]: {
      on: {
        ADD_SUSPENSION: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE,
          actions: [
            updateToLastIndexEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension),
            incrementEditingInsuranceRecordIfOneAlreadyDeclared(
              Driver.Primary,
              InsuranceRecordType.CancellationOrSuspension,
            ),
            setWithdrawalType(Driver.Primary, WithdrawalType.Suspension),
          ],
        },
        ADD_CANCELLATION: {
          target: State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE,
          actions: [
            updateToLastIndexEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.CancellationOrSuspension),
            incrementEditingInsuranceRecordIfOneAlreadyDeclared(
              Driver.Primary,
              InsuranceRecordType.CancellationOrSuspension,
            ),
            setWithdrawalType(Driver.Primary, WithdrawalType.Cancellation),
          ],
        },
        ADD_CLAIM: {
          target: State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE,
          actions: [
            updateToLastIndexEditingInsuranceRecord(Driver.Primary, InsuranceRecordType.Claim),
            incrementEditingInsuranceRecordIfOneAlreadyDeclared(Driver.Primary, InsuranceRecordType.Claim),
          ],
        },
        BACK: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
          },
        ],
      },
    },
    /** RAG primary driver - end */
    // SECONDARY DRIVER
    [State.INFORMATION_DECLARE_SECONDARY_DRIVER]: {
      on: {
        [Event.ANSWER]: [
          { target: State.INFORMATION_SECONDARY_DETAILS, cond: hasDeclaredSecondaryDriver, actions: [copyAnswers] },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers, deleteSecondaryDriver],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.PRIMARY_DRIVER),
          },
          { target: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED, cond: driverHasNeverBeenCovered(Driver.Primary) },
          { target: State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_DETAILS]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_RELATION, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_DECLARE_SECONDARY_DRIVER },
      },
    },
    [State.INFORMATION_SECONDARY_RELATION]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_LICENSE_DATE, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_DETAILS },
      },
    },
    [State.INFORMATION_SECONDARY_LICENSE_DATE]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_LICENSE_TYPE, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_RELATION },
      },
    },
    [State.INFORMATION_SECONDARY_LICENSE_TYPE]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_WAS_ACCOMPANIED, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_LICENSE_DATE },
      },
    },
    [State.INFORMATION_SECONDARY_WAS_ACCOMPANIED]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_HAS_BEEN_COVERED, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_LICENSE_TYPE },
      },
    },
    [State.INFORMATION_SECONDARY_HAS_BEEN_COVERED]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_PERIODS,
            cond: driverHasBeenCoveredPartially(Driver.Secondary),
            actions: [copyAnswers, setInsurancePeriods(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_BONUS_MALUS,
            cond: driverHasBeenCoveredContinuously(Driver.Secondary),
            actions: [copyAnswers, resetInsurancePeriods(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_BONUS_MALUS,
            cond: driverHasNotBeenCoveredOnPeriod(Driver.Secondary),
            actions: [copyAnswers, resetInsurancePeriods(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            actions: [copyAnswers, resetDriverBonusYearsAndInsurancePeriods(Driver.Secondary)],
          },
        ],
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_WAS_ACCOMPANIED },
      },
    },
    [State.INFORMATION_SECONDARY_INSURANCE_PERIODS]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_BONUS_MALUS, actions: [copyAnswers] },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_HAS_BEEN_COVERED },
      },
    },
    [State.INFORMATION_SECONDARY_BONUS_MALUS]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_BONUS50,
            actions: [copyAnswers],
            cond: driverHas50PercentBonus(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD,
            cond: driverHasBeenCoveredOnPeriod(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_HAS_BEEN_COVERED,
            cond: driverHasBeenCoveredContinuously(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_HAS_BEEN_COVERED,
            cond: driverHasNotBeenCoveredOnPeriod(Driver.Secondary),
          },
          { target: State.INFORMATION_SECONDARY_INSURANCE_PERIODS },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_BONUS50]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD,
            cond: driverHasBeenCoveredOnPeriod(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_BONUS_MALUS },
      },
    },

    /** RAG secondary driver - start */
    // TERMINATION (INSURANCE CONTRACT)
    [State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_DATE,
            cond: hasTerminationRecord(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            actions: [copyAnswers, resetTermination(Driver.Secondary)],
          },
        ],
        [Event.BACK]: [
          { target: State.INFORMATION_SECONDARY_BONUS50, cond: driverHas50PercentBonus(Driver.Secondary) },
          { target: State.INFORMATION_SECONDARY_BONUS_MALUS },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON,
          actions: [copyAnswers],
        },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
            cond: or(isTerminatedForCancellation(Driver.Secondary), isTerminatedForSuspension(Driver.Secondary)),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_DATE,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION,
            cond: isTerminatedForSuspension(Driver.Secondary),
            actions: [copyAnswers, resetTerminationCountForNonPayment(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
            actions: [
              copyAnswers,
              resetTerminationCountForNonPayment(Driver.Secondary),
              resetTerminationSuspensionDuration(Driver.Secondary),
            ],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE,
            cond: isTerminationCancellationRelatedToAClaim(Driver.Secondary),
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionTrafficControl(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionClaim(Driver.Secondary)],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION,
            cond: isTerminatedForSuspension(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE,
          },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL,
            cond: doesTerminationCancellationRecordInvolveAlcohol(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionAlcoholLevel(Driver.Secondary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE]: {
      on: {
        [Event.ANSWER]: {
          target:
            State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL,
            cond: isTerminationCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
            actions: [copyAnswers, resetTerminationCancellationOrSuspensionClaimAlcoholLevel(Driver.Secondary)],
          },
        ],
        [Event.BACK]: {
          target:
            State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
        },
      },
    },
    [State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL,
            cond: isTerminationCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
            cond: isTerminationCancellationRelatedToAClaim(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL,
            cond: doesTerminationCancellationRecordInvolveAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
          },
        ],
      },
    },
    // CANCELLATIONS OR SUSPENSIONS (LICENSE)
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE,
            cond: hasBeenCancelledOrSuspended(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY,
            actions: [copyAnswers, resetCancellationsAndSuspensions(Driver.Secondary)],
          },
        ],
        [Event.SKIP]: {
          target: State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY,
          actions: [resetCancellationsAndSuspensions(Driver.Secondary)],
        },
        [Event.BACK]: [
          { target: State.INFORMATION_SECONDARY_HAS_BEEN_COVERED, cond: driverHasNeverBeenCovered(Driver.Secondary) },
          {
            target: State.INFORMATION_SECONDARY_BONUS50,
            cond: and(driverHasNotBeenCoveredOnPeriod(Driver.Secondary), driverHas50PercentBonus(Driver.Secondary)),
          },
          {
            target: State.INFORMATION_SECONDARY_BONUS_MALUS,
            cond: driverHasNotBeenCoveredOnPeriod(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON,
            cond: hasDeclaredTermination(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD,
          },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
            cond: isRecordASuspension(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
            actions: [copyAnswers],
          },
        ],
        // TODO [>1]: Check every flow related to INFORMATION_<DRIVER>_ADD_CANCELLATION_RECORD or INFORMATION_<DRIVER>_CANCELLATION_RECORD[
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
            cond: hasPreviousInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension),
            actions: [decrementEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension)],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
          },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
          actions: [copyAnswers],
        },
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD,
            cond: isAddingAnotherCancellationOrSuspension(Driver.Secondary),
            actions: [decrementEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension)],
          },
          { target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE,
            cond: isCancellationRecordRelatedToAClaim(Driver.Secondary),
            actions: [copyAnswers, resetCancellationOrSuspensionTrafficControl(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON,
            actions: [copyAnswers, resetCancellationOrSuspensionClaim(Driver.Secondary)],
          },
        ],
        // TODO [>1]: Check every flow related to INFORMATION_<DRIVER>_ADD_CANCELLATION_RECORD or INFORMATION_<DRIVER>_CANCELLATION_RECORD
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD,
            cond: isAddingAnotherCancellationOrSuspension(Driver.Secondary),
            actions: [decrementEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension)],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
            cond: isRecordASuspension(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE,
          },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL,
            actions: [copyAnswers],
            cond: doesCancellationRecordInvolveAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN,
            actions: [copyAnswers, resetCancellationOrSuspensionAlcoholLevel(Driver.Secondary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON,
        },
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
        },
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE,
        },
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL,
            actions: [copyAnswers],
            cond: isCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN,
            actions: [copyAnswers, resetCancellationOrSuspensionClaimAlcoholLevel(Driver.Secondary)],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL,
        },
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN,
          actions: [copyAnswers],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
        },
      },
    },
    [State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL,
            cond: isCancellationClaimUnderTheInfluenceOfAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
            cond: isCancellationRecordRelatedToAClaim(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL,
            cond: doesCancellationRecordInvolveAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON,
          },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
            cond: isNextRecordASuspension(Driver.Secondary),
            actions: [
              copyAnswers,
              incrementEditingInsuranceRecordIfOneAlreadyDeclared(
                Driver.Secondary,
                InsuranceRecordType.CancellationOrSuspension,
              ),
            ],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
            actions: [
              copyAnswers,
              incrementEditingInsuranceRecordIfOneAlreadyDeclared(
                Driver.Secondary,
                InsuranceRecordType.CancellationOrSuspension,
              ),
            ],
          },
        ],
        [Event.SKIP]: {
          target: State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY,
          actions: [skipAllNextCancellationOrSuspensionRecords(Driver.Secondary)],
        },
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN,
        },
      },
    },
    // CLAIMS
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY]: {
      on: {
        [Event.REDIRECT]: [{ target: State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON }],
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE,
            actions: [copyAnswers, initializeEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.Claim)],
            cond: doesDriverDeclaredAtLeastOneClaim(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD,
            cond: hasDeclaredCancellationRecords(Driver.Secondary),
            actions: [
              updateToLastIndexEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension),
            ],
          },
          { target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE]: {
      on: {
        [Event.ANSWER]: { target: State.INFORMATION_SECONDARY_CLAIM_RECORD_DATE, actions: [copyAnswers] },
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
          },
          {
            target: State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD,
            cond: hasPreviousInsuranceRecord(Driver.Secondary, InsuranceRecordType.Claim),
            actions: [decrementEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.Claim)],
          },
          { target: State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_DATE]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER,
            cond: isClaimCausedByAnAccident(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Secondary),
            actions: [copyAnswers, resetClaimAccident(Driver.Secondary)],
          },
          { target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY, actions: [copyAnswers] },
        ],
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE },
      },
    },
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER]: {
      on: {
        [Event.ANSWER]: {
          target: State.INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL,
          actions: [copyAnswers],
        },
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_CLAIM_RECORD_DATE },
      },
    },
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL,
            cond: isClaimDriverBehindTheWheel(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers, resetClaimWithAlcoholAnswers(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Secondary),
            actions: [copyAnswers, resetClaimWithAlcoholAnswers(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers, resetClaimWithAlcoholAnswers(Driver.Secondary)],
          },
        ],
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER },
      },
    },
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL,
            cond: isClaimUnderTheInfluenceOfAlcohol(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers, resetClaimAlcoholLevel(Driver.Secondary)],
          },
          {
            target: State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Secondary),
            actions: [copyAnswers, resetClaimAlcoholLevel(Driver.Secondary)],
          },
          { target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY, actions: [copyAnswers] },
        ],
        [Event.BACK]: { target: State.INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL },
      },
    },
    [State.INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL]: {
      on: {
        [Event.ANSWER]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            cond: hasReachedSummaryScreen(Section.SECONDARY_DRIVER),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD,
            cond: doesDriverDeclaredMultipleClaims(Driver.Secondary),
            actions: [copyAnswers],
          },
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            actions: [copyAnswers],
          },
        ],
        [Event.BACK]: {
          target: State.INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL,
        },
      },
    },
    [State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD]: {
      on: {
        [Event.ADD_CLAIM]: {
          target: State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE,
          actions: [incrementEditingInsuranceRecordIfOneAlreadyDeclared(Driver.Secondary, InsuranceRecordType.Claim)],
        },
        [Event.CONTINUE]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
          },
        ],
        [Event.BACK]: [
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_DATE,
            cond: isClaimNotCausedByAnAccident(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL,
            cond: isClaimAnotherDriverBehindTheWheel(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL,
            cond: isClaimUnderTheInfluenceOfAlcohol(Driver.Secondary),
          },
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL,
          },
        ],
      },
    },
    // SUMMARY

    [State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY]: {
      entry: setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], true),
      on: {
        [Event.RETURN_TO_SCREEN]: generateTargets(Section.SECONDARY_DRIVER),
        [Event.RESTITUTION_DELETE_SECONDARY_DRIVER]: {
          target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
          actions: [deleteSecondaryDriver],
        },
        [Event.RESTITUTION_EDIT_SECONDARY_DRIVER]: {
          target: State.INFORMATION_SECONDARY_DETAILS,
          actions: [resetSubscription, declareSecondaryDriver],
        },
        [Event.CONTINUE]: [
          {
            target: State.INFORMATION_PRIMARY_DETAILS,
          },
        ],
        [Event.EDIT_INSURANCE_RECORD]: [
          {
            target: State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE,
            cond: isEditingRecordAClaim,
            actions: [
              updateEditingInsuranceRecordFromEvent(Driver.Secondary),
              setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
            ],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE,
            cond: isEditingRecordASuspension(Driver.Secondary),
            actions: [
              updateEditingInsuranceRecordFromEvent(Driver.Secondary),
              setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
            ],
          },
          {
            target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
            actions: [
              updateEditingInsuranceRecordFromEvent(Driver.Secondary),
              setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
            ],
          },
        ],
        [Event.DELETE_INSURANCE_RECORD]: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
            actions: deleteInsuranceRecordFromEvent(Driver.Secondary),
          },
        ],
        [Event.START_ADDING_INSURANCE_RECORD]: {
          target: State.INFORMATION_SECONDARY_ADD_INSURANCE_RECORD,
          actions: setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
        },
        [Event.BACK]: [
          {
            target: State.INFORMATION_DECLARE_SECONDARY_DRIVER,
            cond: not(hasDeclaredSecondaryDriver),
            actions: setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
          },
          {
            target: State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD,
            cond: driverHasBeenCoveredOnPeriod(Driver.Secondary),
            actions: setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
          },
          {
            target: State.INFORMATION_SECONDARY_BONUS50,
            cond: driverHas50PercentBonus(Driver.Secondary),
            actions: setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
          },
          {
            target: State.INFORMATION_SECONDARY_BONUS_MALUS,
            actions: setReachedSummaryScreenSectionBuilder([Section.SECONDARY_DRIVER], false),
          },
        ],
      },
    },
    [State.INFORMATION_SECONDARY_ADD_INSURANCE_RECORD]: {
      on: {
        ADD_SUSPENSION: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE,
          actions: [
            updateToLastIndexEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension),
            incrementEditingInsuranceRecordIfOneAlreadyDeclared(
              Driver.Secondary,
              InsuranceRecordType.CancellationOrSuspension,
            ),
            setWithdrawalType(Driver.Secondary, WithdrawalType.Suspension),
          ],
        },
        ADD_CANCELLATION: {
          target: State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
          actions: [
            updateToLastIndexEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.CancellationOrSuspension),
            incrementEditingInsuranceRecordIfOneAlreadyDeclared(
              Driver.Secondary,
              InsuranceRecordType.CancellationOrSuspension,
            ),
            setWithdrawalType(Driver.Secondary, WithdrawalType.Cancellation),
          ],
        },
        ADD_CLAIM: {
          target: State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE,
          actions: [
            updateToLastIndexEditingInsuranceRecord(Driver.Secondary, InsuranceRecordType.Claim),
            incrementEditingInsuranceRecordIfOneAlreadyDeclared(Driver.Secondary, InsuranceRecordType.Claim),
          ],
        },
        BACK: [
          {
            target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
          },
        ],
      },
    },
    [State.INFORMATION_PRIMARY_DETAILS]: {
      on: {
        [Event.ANSWER]: {
          target: State.TARIFICATION_LOADING,
          actions: [copyAnswers, 'sendUserFillsForm'],
        },
        [Event.CONTINUE]: {
          target: State.FORMULES,
        },
        [Event.BACK]: [{ target: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY }],
      },
    },
    [State.TARIFICATION_LOADING]: {
      entry: [resetError, resetTarificationResult, 'sendUserWaitsForLongQuote'],
      invoke: {
        id: 'getTarification',
        src: async (context) => getQuote(context.answers, context.segmentAnonymousId, context.agentEmail),
        onDone: [
          {
            target: State.INFORMATION_PRIMARY_DETAILS,
            cond: noProviderAvailable,
            actions: [onTarificationError],
          },
          {
            target: State.CANNOT_QUOTE,
            cond: tarificationFailed,
            actions: [onTarificationResult, 'sendUserCompletesLongQuote'],
          },
          {
            target: State.REVERSE_SUBSCRIPTION,
            cond: hasUnderwritingIssues,
          },
          {
            target: State.FORMULES,
            actions: [onTarificationResult, showFicNotification, resetError, 'sendUserCompletesLongQuote'],
          },
        ],
        onError: {
          target: State.CANNOT_QUOTE,
          actions: [sendErrorToSentry],
        },
      },
    },
    [State.NEEDS_SALES_VALIDATION]: {
      on: {
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_DETAILS },
      },
    },
    [State.FORMULES]: {
      on: {
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_DETAILS },
        [Event.FORMULE_SELECTED]: [
          {
            target: State.POST_FORMULE_DISPATCH,
            actions: [saveQuoteResult, clearDeductibles, resetFicMailDisplay, resetQuoteMailNotification],
          },
        ],
      },
    },
    [State.CANNOT_QUOTE]: {
      on: {
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_DETAILS },
      },
    },
    [State.CANNOT_RETRIEVE_QUOTE]: {
      on: {
        [Event.CONTINUE]: { target: State.VEHICULE_SEARCH_CHOICE },
      },
    },
    [State.REVERSE_SUBSCRIPTION]: {
      on: {
        [Event.BACK]: { target: State.INFORMATION_PRIMARY_DETAILS },
      },
    },

    /** -- NOTTINGHAM POST QUOTATION SECTIONS -- Start */
    [State.POST_FORMULE_DISPATCH]: {
      entry: [savePickedFormule, setDefaultDeductibles],
      exit: ['sendUserCustomizeInsuranceContract'],
      always: [
        { target: State.NEEDS_SALES_VALIDATION, cond: needSalesValidation },
        { target: State.OPTIONS_INTRODUCTION },
      ],
    },
    [State.OPTIONS_INTRODUCTION]: {
      entry: checkAndTransformAddons,
      on: {
        [Event.CONTINUE]: [
          { target: State.ADDON_0KM_ASSISTANCE, cond: isTinderStyleOptions },
          { target: State.ADDON_OPTIONS_PACKAGES },
        ],
        [Event.BACK]: { target: State.FORMULES },
      },
    },
    [State.ADDON_0KM_ASSISTANCE]: {
      entry: checkAndTransformAddons,
      on: {
        [Event.BACK]: { target: State.OPTIONS_INTRODUCTION },
        [Event.ANSWER_WITHOUT_CONTINUING]: { actions: [copyAnswers] },
        [Event.ANSWER]: {
          target: State.ADDON_DRIVER_PROTECTION_500,
          actions: [copyAnswers],
        },
      },
    },
    [State.ADDON_DRIVER_PROTECTION_500]: {
      entry: checkAndTransformAddons,
      on: {
        [Event.BACK]: { target: State.ADDON_0KM_ASSISTANCE },
        [Event.ANSWER]: [
          {
            target: State.ADDON_VEHICLE_LOAN,
            actions: [copyAnswers],
          },
        ],
      },
    },
    [State.ADDON_VEHICLE_LOAN]: {
      entry: checkAndTransformAddons,
      on: {
        [Event.BACK]: [
          { cond: isTinderStyleOptions, target: State.ADDON_DRIVER_PROTECTION_500 },
          { target: State.ADDON_OPTIONS_PACKAGES },
        ],
        [Event.ANSWER]: [
          {
            target: State.DEDUCTIBLES_SELECTION,
            cond: haveDeductibles,
            actions: [copyAnswers],
          },
          {
            target: State.SENDING_SELECTED_OPTIONS_AND_CLEARING_PROMO_CODE,
            actions: [copyAnswers],
          },
        ],
      },
    },
    [State.ADDON_OPTIONS_PACKAGES]: {
      entry: checkAndTransformAddons,
      on: {
        [Event.ANSWER_WITHOUT_CONTINUING]: { actions: [copyAnswers] },
        [Event.CONTINUE]: [
          {
            target: State.ADDON_VEHICLE_LOAN,
          },
        ],
        [Event.BACK]: { target: State.OPTIONS_INTRODUCTION },
      },
    },
    [State.DEDUCTIBLES_SELECTION]: {
      entry: checkAndTransformAddons,
      on: {
        [Event.BACK]: { target: State.ADDON_VEHICLE_LOAN },
        [Event.ANSWER_WITHOUT_CONTINUING]: { actions: [copyAnswers] },
        [Event.CONTINUE]: {
          target: State.SENDING_SELECTED_OPTIONS_AND_CLEARING_PROMO_CODE,
        },
      },
    },
    [State.SENDING_SELECTED_OPTIONS_AND_CLEARING_PROMO_CODE]: {
      entry: checkAndTransformAddons,
      invoke: {
        src: async (context) =>
          Promise.all([
            sendQuoteOptions((context.tarificationResult as TarificationResultOK).quoteId, context.answers),
            promoCodeCloseSession({
              submissionId: context.formulePickedObject?.submissionId || '',
            }),
          ]),
        onDone: [
          {
            target: State.SHOPPING_CART,
          },
        ],
        onError: {
          actions: [sendErrorToSentry],
        },
      },
    },
    [State.SHOPPING_CART]: {
      on: {
        [Event.BACK]: [
          { cond: haveDeductibles, target: State.DEDUCTIBLES_SELECTION },
          { cond: isTinderStyleOptions, target: State.ADDON_0KM_ASSISTANCE },
          { target: State.ADDON_OPTIONS_PACKAGES },
        ],
        [Event.ANSWER_WITHOUT_CONTINUING]: { actions: [copyAnswers] },
        [Event.CONTINUE]: [
          {
            target: State.HAMON_LAW_INFORMATION,
            cond: isEligibleToHamonLaw,
          },
          {
            target: State.INSURED_SINCE_LESS_THAN_A_YEAR,
            cond: isInsuredSinceLessThanAYear,
            actions: ['sendHamonLawScreenDisplayed'],
          },
          {
            target: State.VEHICLE_LEGAL_INFORMATION,
          },
        ],
        [Event.SHOPPING_CART_ERROR]: { target: State.CANNOT_QUOTE, actions: [sendErrorToSentry] },
      },
    },
    [State.HAMON_LAW_INFORMATION]: {
      on: {
        [Event.BACK]: { target: State.SHOPPING_CART },
        [Event.ANSWER]: { target: State.VEHICLE_LEGAL_INFORMATION, actions: [copyAnswers] },
        [Event.ANSWER_WITHOUT_CONTINUING]: { target: State.HAMON_LAW_INFORMATION, actions: [copyAnswers] },
      },
    },
    [State.INSURED_SINCE_LESS_THAN_A_YEAR]: {
      on: {
        [Event.BACK]: [{ target: State.SHOPPING_CART }],
      },
    },
    [State.VEHICLE_LEGAL_INFORMATION]: {
      on: {
        [Event.BACK]: [
          { target: State.HAMON_LAW_INFORMATION, cond: isEligibleToHamonLaw },
          { target: State.SHOPPING_CART },
        ],
        [Event.CONTINUE]: { target: State.LRE_INFORMATION },
        [Event.ANSWER_WITHOUT_CONTINUING]: {
          actions: [setVehicleInfo],
        },
        [Event.ANSWER]: {
          target: State.TARIFICATION_LOADING,
          actions: [setVehicleInfo],
        },
      },
    },
    [State.LRE_INFORMATION]: {
      on: {
        [Event.BACK]: { target: State.VEHICLE_LEGAL_INFORMATION },
        [Event.CONTINUE]: { target: State.REGISTER_IBAN },
      },
    },
    [State.REGISTER_IBAN]: {
      on: {
        [Event.BACK]: { target: State.LRE_INFORMATION },
        [Event.ANSWER]: { target: State.CONFIRM_SEPA_MANDATE, actions: [copyAnswers] },
        [Event.QUOTE_MAIL_NOTIFICATION_RECEIVED]: { actions: [markQuoteMailNotificationAsReceived] },
      },
    },
    [State.CONFIRM_SEPA_MANDATE]: {
      on: {
        [Event.BACK]: { target: State.REGISTER_IBAN },
        [Event.CONTINUE]: { target: State.CREDIT_CARD_PAYMENT },
      },
    },
    [State.CREDIT_CARD_PAYMENT]: {
      on: {
        [Event.PAYMENT_ERROR]: {
          target: State.CREDIT_CARD_PAYMENT,
          actions: ['sendUserPaymentAttemptDeclined'],
        },
        [Event.PAYMENT_SUCCESS]: {
          target: State.WEBAPP_INTRODUCTION,
          actions: ['sendUserPaymentAttemptValidated', 'sendUserClosesNewOpportunityOnline'],
        },
        [Event.BACK]: [{ target: State.CONFIRM_SEPA_MANDATE }],
      },
    },

    /** -- NOTTINGHAM POST QUOTATION SECTIONS -- End */
    [State.WEBAPP_INTRODUCTION]: {
      type: 'final',
    },
  },
};

export const initializeMachine = (
  id = 'machine-id',
  initialState?: State,
  initialContext?: Context,
  customConfig?: InsuranceFsmConfig,
): StateMachine<Context, Schema, Events> => {
  return createMachine({
    id,
    ...machineDefinition,
    context: initializeContext(initialContext),
    initial: initialState || State.VEHICULE_SEARCH_CHOICE,
  }).withConfig({
    actions: { ...customConfig?.actions },
    guards: { ...customConfig?.guards },
  });
};
