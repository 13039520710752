import { DocuSigned, IllustratedIcon, Mail, Notebook } from '@ornikar/illustrated-icons';
import { HStack, Message, Tag, Typography, TypographyLink, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { MrhRedirection } from '../../../components/MrhRedirection';
import { RegistrationDocumentPartnerLink } from '../../../components/RegistrationDocumentPartner';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { MRH_REDIRECTION_FEATURE, WELCOME_SCREEN_MRH_REDIRECTION_FEATURE } from '../../../constants/flagshipKeys';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { isRegistrationDocumentPartnerLinkDisplayed } from '../../../fsm/guards/flagship';
import { useDocumentsNeeded } from '../../../hooks/useDocumentsNeeded';
import { useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import { useFsFlag } from '../../../setup/flagship';
import { getUserName } from '../../Tarification/FormulesScreen/userNameMapper';
import ThumbUp from './assets/FeedbackIllustration.svg';
import Illustration from './assets/WelcomeGomar.svg';
import styles from './styles.module.css';

export function WebappIntroductionScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  useSendPageNameToSegment();

  const documentsList = useDocumentsNeeded();

  const mrhRedirectionFeatureIntroductionScreen = useFsFlag(WELCOME_SCREEN_MRH_REDIRECTION_FEATURE, false, {
    shouldSendVariationToMixpanel: true,
  });

  const mrhRedirectionFeature = useFsFlag(MRH_REDIRECTION_FEATURE, false, {
    shouldSendVariationToMixpanel: true,
  });

  return (
    <ScreenTemplate hideSaveQuoteButton hideSubmitButton hideBreadcrumbs className={styles.Screen}>
      <Typography.h3 color="black" textAlign="center">
        {getUserName(answers)}, plus que quelques pas pour valider définitivement votre contrat !
      </Typography.h3>

      <View marginTop={{ base: 'kitt.8', small: 'kitt.10' }} marginBottom="kitt.6">
        <Typography.h4 variant="bold">Les dernières étapes</Typography.h4>
      </View>

      <HStack space="kitt.6">
        <View position="relative">
          <IllustratedIcon icon={<Mail />} size={48} />
          <View
            bottom={{ base: '-kitt.3', medium: '-kitt.5' }}
            left="50%"
            height="4/5"
            borderWidth={0.5}
            position="absolute"
            borderColor="black"
          />
        </View>
        <VStack flexShrink={1} space="kitt.1">
          <Typography.Text variant="bold">Vous avez reçu un e-mail !</Typography.Text>
          <Typography.Text color="black-anthracite" alignItems="baseline">
            <View
              backgroundColor="kitt.blackAnthracite"
              width="kitt.1"
              height="kitt.1"
              marginLeft="kitt.3"
              marginBottom="2"
              marginRight="kitt.2"
              borderRadius="kitt.2"
            />
            <Typography.Text variant="bold">Nouveau client ?</Typography.Text> Cliquez sur le lien dans l'e-mail et
            activez votre espace client.
          </Typography.Text>
          <Typography.Text color="black-anthracite">
            <View
              backgroundColor="kitt.blackAnthracite"
              width="kitt.1"
              height="kitt.1"
              marginLeft="kitt.3"
              marginBottom="2"
              marginRight="kitt.2"
              borderRadius="kitt.2"
            />
            <Typography.Text variant="bold">Vous avez déjà un compte ?</Typography.Text> Connectez-vous directement avec
            vos identifiants habituels !
          </Typography.Text>
        </VStack>
      </HStack>

      <HStack space="kitt.6" paddingTop="kitt.6">
        <View position="relative">
          <IllustratedIcon icon={<DocuSigned />} size={48} />
          <View
            bottom={{ base: '-kitt.3', medium: '-kitt.5' }}
            left="50%"
            height="2/5"
            borderWidth={0.5}
            position="absolute"
            borderColor="black"
          />
        </View>
        <VStack flexShrink={1} space="kitt.1">
          <Typography.Text variant="bold">Signez votre contrat</Typography.Text>
          <Typography.Text color="black-anthracite">
            Aucune paperasse, la signature se fait directement en ligne.
          </Typography.Text>
        </VStack>
      </HStack>

      <HStack space="kitt.6" paddingTop="kitt.6">
        <View position="relative">
          <IllustratedIcon icon={<Notebook />} size={48} />
          <View bottom="-kitt.3" left="50%" height="70%" borderWidth={0.5} position="absolute" borderColor="black" />
        </View>
        <VStack flexShrink={1} space="kitt.1">
          <Typography.Text variant="bold">Transmettez vos documents</Typography.Text>
          <Typography.Text color="black-anthracite">
            Vous avez 30 jours pour transmettre depuis votre espace client les documents suivants :
          </Typography.Text>
          <VStack space="kitt.1">
            {documentsList.map((document) => (
              <Tag
                key={document}
                label={<Typography.Text base="body-small">{document}</Typography.Text>}
                type="primary"
              />
            ))}
          </VStack>
          {isRegistrationDocumentPartnerLinkDisplayed() ? (
            <RegistrationDocumentPartnerLink textColor="black-anthracite" />
          ) : null}
        </VStack>
      </HStack>

      <HStack space="kitt.6" paddingTop="kitt.4" alignItems="center">
        <View>
          <img src={ThumbUp} alt="happy girl illustration" height={48} />
        </View>
        <Typography.Text variant="bold">Votre contrat est validé</Typography.Text>
      </HStack>

      {mrhRedirectionFeature.getValue() && mrhRedirectionFeatureIntroductionScreen.getValue() ? (
        <View marginTop={{ base: 'kitt.6', small: 'kitt.10' }}>
          <MrhRedirection slug="WEBAPP_INTRODUCTION" utmCampaign="subscription_welcome" />
        </View>
      ) : null}

      <View marginTop={{ base: 'kitt.6', small: 'kitt.10' }}>
        <Message type="warning">
          <VStack space="kitt.5">
            <Typography.Text>
              Vous n'avez pas reçu votre email d'activation ? Pas d'inquiétude, la réception peut prendre jusqu'à 20
              minutes.
            </Typography.Text>
            <Typography.Text>
              Vous n'avez toujours pas reçu l'email après 20 minutes ?
              <br />
              <TypographyLink
                noUnderline
                variant="bold"
                color="primary"
                href="https://survey.survicate.com/64d80b30b481027a/?p=anonymous"
                hrefAttrs={{ target: '__blank' }}
              >
                Contactez le support via ce formulaire.
              </TypographyLink>
            </Typography.Text>
          </VStack>
        </Message>
      </View>
      <View marginTop={{ base: 'kitt.6', small: 'kitt.10' }} marginBottom="-kitt.8">
        <img src={Illustration} alt="happy girl illustration" height={280} />
      </View>
    </ScreenTemplate>
  );
}
